<template>
  <div
    v-if="product && product.name"
    class="sidebar__item"
    :class="[
      loading && 'sidebar__item__disabled',
      removed && 'sidebar__item__removed',
    ]"
  >
    <nuxt-link
      :to="localePath(productUrl)"
      class="sidebar__item__image__container"
    >
      <nuxt-img
        class="sidebar__item__image"
        :src="thumbnailUrl"
        alt="product image"
      />
    </nuxt-link>
    <div class="sidebar__item__sku">
      <span class="sidebar__item__sku__value">{{ productMerchantSku }}</span>
    </div>
    <div class="sidebar__item__name">
      <span class="sidebar__item__name__value">{{ product.name }}</span>
    </div>
    <div class="sidebar__item__price">
      <span class="sidebar__item__price__special" v-show="hasSpecialPrice">
        {{ $fc(productPrice.minimum_price.final_price.value) }}
      </span>
      <span
        class="sidebar__item__price__regular"
        :class="hasSpecialPrice && 'sidebar__item__price__regular__barred'"
      >
        {{ $fc(productPrice.minimum_price.regular_price.value) }}
      </span>
    </div>
    <div class="sidebar__item__options" v-show="simpleProductData">
      <div
        class="sidebar__item__options__option"
        v-for="(option, optionIndex) in simpleProductData"
        :key="`wishlist-item-${product.uid}-${optionIndex}`"
      >
        <span class="sidebar__item__options__option__label">
          {{ `${option.label}:` }}
        </span>
        <span class="sidebar__item__options__option__value">
          {{ option.value }}
        </span>
      </div>
    </div>
    <div class="sidebar__item__remove" @click="handleRemove">
      <Cross />
    </div>
    <div class="sidebar__item__add-to-cart" @click="handleAddToCart">
      <CartIcon />
    </div>
  </div>
  <div v-else />
</template>

<script>
import {
  defineComponent,
  computed,
  shallowRef,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import { Cross, CartIcon } from '~/components/General/Icons';
import { useWishlist, useCart } from '~/composables';
import { productGetters } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'WishlistSidebarItem',
  components: { Cross, CartIcon },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    wishlistItemUid: {
      type: String,
      required: true,
    },
    wishlistItemGrn: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { removeItemFromWishlist, toggleWishlistSidebar } = useWishlist();
    const { getSlug } = productGetters;
    const { addItem } = useCart();
    const { localePath } = useContext();
    const router = useRouter();

    const loading = shallowRef(false);
    const removed = shallowRef(false);

    const productMerchantSku = computed(() =>
      !props?.wishlistItemGrn?.includes('/')
        ? props?.product?.merchant_sku
        : props?.product?.variants?.find(
            (v) => v?.product?.uid === props?.wishlistItemGrn
          )?.product?.merchant_sku
    );

    const productSku = computed(() =>
      !props?.wishlistItemGrn?.includes('/')
        ? props?.product?.sku
        : props?.product?.variants?.find(
            (v) => v?.product?.uid === props?.wishlistItemGrn
          )?.product?.uid
    );

    const productPrice = computed(() =>
      !props?.wishlistItemGrn?.includes('/')
        ? props?.product?.price_range
        : props?.product?.variants?.find(
            (v) => v?.product?.uid === props?.wishlistItemGrn
          )?.product?.price_range
    );

    const hasSpecialPrice = computed(
      () =>
        productPrice?.value?.minimum_price?.final_price?.value <
        productPrice?.value?.minimum_price?.regular_price?.value
    );
    const thumbnailUrl = computed(() =>
      props.product?.thumbnail?.url
        ? `${props.product?.thumbnail?.url}?w=200`
        : '/logo.jpg'
    );

    const productUrl = computed(() => getSlug(props.product));

    const simpleProductData = computed(() => {
      if (!props.wishlistItemGrn.includes('/')) return null;
      const variant = props.product.variants.find(
        (v) => v.product.uid === props.wishlistItemGrn
      );
      const selectedOptions = variant.option_uids;
      const optionValues = selectedOptions.map((o) => {
        const attributeUid = o.split(':')[0];
        const option = props.product.configurable_options.find(
          (p) => p.attribute_uid === attributeUid
        );
        const valueLabel = option.values.find((v) => v.uid === o).label;
        const optionLabel = option.label;
        return {
          label: optionLabel,
          value: valueLabel,
        };
      });
      return optionValues;
    });

    const handleRemove = async () => {
      loading.value = true;
      await removeItemFromWishlist(productSku.value);
      removed.value = true;
      loading.value = false;
    };

    const handleAddToCart = async () => {
      const simpleProductSku = props.wishlistItemGrn.includes('/')
        ? props.wishlistItemGrn
        : null;
      if (!simpleProductSku) {
        router.push({
          path: localePath(productUrl.value),
          query: { addToCart: true },
        });
        return;
      }
      loading.value = true;
      const quantity = 1;
      await toggleWishlistSidebar();
      await addItem(
        {
          item: {
            ...props.product,
            configurable_product_options_selection: {
              variant: { sku: simpleProductSku },
            },
          },
          quantity,
        },
        true // open cart sidebar
      );
      loading.value = false;
    };

    return {
      handleRemove,
      handleAddToCart,
      hasSpecialPrice,
      loading,
      thumbnailUrl,
      productUrl,
      simpleProductData,
      productPrice,
      productMerchantSku,
      removed,
    };
  },
});
</script>

<style lang="scss" scoped>
.sidebar__item {
  display: grid;
  grid-template-columns: 30% 1fr 20% 20%;
  grid-template-areas:
    'image sku sku remove'
    'image name name name'
    'image options options options'
    'image price price add-to-cart';
  column-gap: 0.5rem;
  row-gap: 0.3125rem;
  border-bottom: 1px solid var(--c-grey);
  padding: 1rem 0;
  &__image {
    &__container {
      grid-area: image;
      @include flex-center;
    }
    width: 70%;
    height: 70%;
    object-fit: contain;
  }
  &__name {
    grid-area: name;
    word-break: break-word;
    padding-top: 0.2rem;
    font-family: var(--font-family-albert-sans);
    font-size: 1rem;
    letter-spacing: 0.01rem;
    line-height: 1.5625rem;
    font-weight: 600;
  }
  &__sku {
    grid-area: sku;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: var(--font-family-albert-sans);
    font-size: 0.875rem;
    letter-spacing: 0.0963rem;
    line-height: 1.5625rem;
    color: var(--c-primary-light);
  }
  &__price {
    grid-area: price;
    display: flex;
    gap: 0.2rem;
    align-items: center;
    &__regular {
      &__barred {
        text-decoration: line-through;
      }
    }
    &__regular,
    &__special {
      font-family: var(--font-family-albert-sans);
      font-size: 1.25rem;
      line-height: 1.5625rem;
    }
    &__special {
      font-weight: bold;
    }
  }
  &__remove {
    grid-area: remove;
  }
  &__add-to-cart {
    grid-area: add-to-cart;
  }
  &__remove,
  &__add-to-cart {
    @include flex-center;
    cursor: pointer;
    margin: 0.3rem;
    scale: 0.8;
  }
  &__disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &__removed {
    display: none;
  }
  &__options {
    grid-area: options;
    display: flex;
    flex-direction: column;
    &__option {
      display: flex;
      flex-direction: row;
      gap: 0.2rem;
      align-items: center;
      &__label,
      &__value {
        font-family: var(--font-family-albert-sans);
        font-size: 0.875rem;
        line-height: 1rem;
        letter-spacing: 0.0963rem;
        text-transform: uppercase;
      }
      &__label {
        font-weight: 600;
      }
      &__value {
        color: var(--c-primary-light);
      }
    }
  }
}
@include from-desktop-min {
  .sidebar__item {
    &__price {
      gap: 1rem;
      flex-direction: row;
    }
  }
}
</style>
