<template>
  <div class="login-popup">
    <SfModal
      :visible="!!loginAndRegisterModalOpen"
      class="modal"
      @close="setModal('')"
    >
      <template #modal-bar>
        <SfBar
          class="sf-modal__bar smartphone-only"
          :close="true"
          :title="$t(barTitle)"
          @click:close="setModal('')"
        />
      </template>
      <transition name="sf-fade" mode="out-in">
        <div v-if="loginAndRegisterModalOpen === 'login'">
          <ValidationObserver
            v-slot="{ handleSubmit, invalid }"
            :key="`log-in-${loginAndRegisterModalOpen}`"
          >
            <form
              id="login-form"
              class="form"
              @submit.prevent="handleSubmit(handleLogin)"
            >
              <ValidationProvider v-slot="{ errors }" rules="required|email">
                <SfInput
                  v-model="form.username"
                  v-e2e="'login-modal-email'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="email"
                  :label="$t('Your email')"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <SfInput
                  v-model="form.password"
                  v-e2e="'login-modal-password'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="password"
                  :label="$t('Password')"
                  type="password"
                  has-show-password
                  class="form__element"
                />
              </ValidationProvider>
              <div class="error-message" v-if="error.login">
                {{ $t(error.login) }}
              </div>
              <CustomButton
                v-e2e="'login-modal-submit'"
                type="submit"
                class="sf-button--full-width form__button btn-yellow sf-button"
                :disabled="loading || invalid"
                theme="black"
                :is-full-width="true"
              >
                <SfLoader :class="{ loader: loading }" :loading="loading">
                  <div>{{ $t('Login') }}</div>
                </SfLoader>
              </CustomButton>
              <br />
            </form>
          </ValidationObserver>
          <div class="action">
            <span class="sf-button-link" @click="setModal('forgotten')">
              {{ $t('Forgotten password?') }}
            </span>
          </div>
          <div class="bottom">
            <p class="no-account">
              {{ $t('No account') }}
            </p>
            <span class="sf-button-link" @click="setModal('register')">
              {{ $t('Register here') }}
            </span>
          </div>
        </div>
        <div v-else-if="loginAndRegisterModalOpen === 'forgotten'">
          <p>{{ $t('Forgot Password') }}</p>
          <ValidationObserver v-slot="{ handleSubmit }" key="log-in">
            <form
              id="forgotten-form"
              class="form"
              @submit.prevent="handleSubmit(handleForgotten)"
            >
              <ValidationProvider v-slot="{ errors }" rules="required|email">
                <SfInput
                  v-model="form.username"
                  v-e2e="'forgot-modal-email'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="email"
                  :label="$t('Forgot Password Modal Email')"
                  class="form__element"
                />
              </ValidationProvider>
              <div v-if="forgotPasswordError.request" class="thank-you">
                <p class="thank-you__paragraph">
                  {{
                    $t(
                      'It was not possible to request a new password, please check the entered email address.'
                    )
                  }}
                </p>
                <br />
              </div>
              <CustomButton
                v-e2e="'forgot-modal-submit'"
                type="submit"
                theme="black"
                is-full-width
                class="sf-button--full-width form__button btn-yellow sf-button"
                :disabled="forgotPasswordLoading || !form.username"
              >
                <SfLoader
                  :class="{ loader: forgotPasswordLoading }"
                  :loading="forgotPasswordLoading"
                >
                  <div>{{ $t('Reset Password') }}</div>
                </SfLoader>
              </CustomButton>
              <div class="action">
                <p class="no-account">{{ $t('or') }}</p>
                <div
                  v-e2e="'login-modal-login-to-your-account'"
                  class="sf-button-link"
                  @click="setModal('login')"
                >
                  {{ $t('login in to your account') }}
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div v-else-if="isThankYouAfterForgotten" class="thank-you">
          <i18n
            tag="p"
            class="thank-you__paragraph"
            path="forgotPasswordConfirmation"
          >
            <span class="thank-you__paragraph--bold">{{ userEmail }}</span>
          </i18n>
          <p class="thank-you__paragraph">
            {{ $t('Thank You Inbox') }}
          </p>
        </div>
        <div v-else>
          <Register />
        </div>
      </transition>
    </SfModal>
  </div>
</template>

<script>
import {
  ref,
  watch,
  reactive,
  defineComponent,
  computed,
  useContext,
  useRouter,
  shallowRef,
  onMounted,
  onDeactivated,
} from '@nuxtjs/composition-api';
import { SfModal, SfInput, SfLoader, SfBar } from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, confirmed } from 'vee-validate/dist/rules';
import { useForgotPassword } from '@gemini-vsf/composables';
import {
  useUiState,
  useUser,
  useGtm,
  useUserWishlists,
  useRecaptcha,
  useUiNotification,
  useTranslation,
} from '~/composables';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/helpers/customer/regex';
import { removeItem } from '~/helpers/asyncLocalStorage';
import { Register } from '~/components/Customer';
import CustomButton from '~/components/General/FormElements/CustomButton.vue';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('confirmed', {
  ...confirmed,
  message: 'Please make sure your passwords match',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'LoginAndRegisterModal',
  components: {
    CustomButton,
    SfModal,
    SfInput,
    SfLoader,
    ValidationProvider,
    ValidationObserver,
    SfBar,
    Register,
  },
  setup() {
    const { loginAndRegisterModalOpen, toggleLoginAndRegisterModal } =
      useUiState();
    const { send: sendNotification } = useUiNotification();
    const { $gt } = useTranslation('translations');
    const acceptMarketing = ref(false);
    const form = ref({});
    const selectedCustomerGroup = shallowRef();
    const terms = ref(false);
    const rememberMe = ref(false);
    const isThankYouAfterForgotten = ref(false);
    const userEmail = ref('');
    const { isEnabled: isRecaptchaEnabled, $recaptcha } = useRecaptcha();
    const {
      app: { localePath },
    } = useContext();
    const router = useRouter();
    const { loginPush } = useGtm();
    const { loadWishlist } = useUserWishlists();

    const {
      login,
      loading: loadingUser,
      user,
      error: userError,
      load: loadUser,
    } = useUser();
    const {
      request,
      error: forgotPasswordError,
      loading: forgotPasswordLoading,
    } = useForgotPassword();

    const loadingSubmit = ref(false);
    const loading = computed(() => loadingUser.value || loadingSubmit.value);

    const barTitle = computed(() => {
      if (loginAndRegisterModalOpen.value === 'login') {
        return 'Sign in';
      }
      if (
        loginAndRegisterModalOpen.value === 'forgotten' ||
        isThankYouAfterForgotten.value
      ) {
        return 'Reset Password';
      }
      return 'Register';
    });

    const error = reactive({
      login: null,
      register: null,
    });

    const resetErrorValues = () => {
      error.login = null;
      error.register = null;
    };

    watch(loginAndRegisterModalOpen, () => {
      if (loginAndRegisterModalOpen) {
        form.value = {};
        resetErrorValues();
      }
    });

    const setModal = (modal) => {
      resetErrorValues();
      toggleLoginAndRegisterModal(modal);
    };

    const handleForm = (function_) => async () => {
      loadingSubmit.value = true;
      resetErrorValues();
      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await $recaptcha.execute('login');
        await function_({
          user: {
            ...form.value,
            is_subscribed: acceptMarketing.value,
            recaptchaToken,
          },
        });
      } else {
        await function_({
          user: {
            ...form.value,
            is_subscribed: acceptMarketing.value,
          },
        });
      }
      const hasUserErrors = userError.value.register || userError.value.login;
      if (hasUserErrors) {
        error.login = userError.value.login?.message;
        error.register = userError.value.register?.message;
        await loadUser();
        loadingSubmit.value = false;
        return;
      }
      await loadUser();
      toggleLoginAndRegisterModal();
      await removeItem('checkout');
      router.push(localePath('/my-account'));
      loadingSubmit.value = false;
    };

    const handleLogin = async () => {
      await handleForm(login)();
      await loadWishlist();
      form.value = {};
      loginPush(user.value);
    };

    const handleForgotten = async () => {
      userEmail.value = form.value.username;
      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await $recaptcha.execute('forgot');
        await request({ email: userEmail.value, recaptchaToken });
      } else {
        await request({ email: userEmail.value });
      }
      if (!forgotPasswordError.value.request) {
        isThankYouAfterForgotten.value = true;
        sendNotification({
          id: Symbol('reset_password_ok'),
          message: $gt(
            'The request has been sent, an email to change your password will arrive shortly.'
          ),
          type: 'success',
          icon: 'success',
          persist: false,
          title: 'Success',
        });
        toggleLoginAndRegisterModal('login');
      } else {
        sendNotification({
          id: Symbol('reset_password_error'),
          message: $gt('Error while sending password recovery email.'),
          type: 'danger',
          icon: 'danger',
          persist: false,
          title: 'Error',
        });
      }
    };

    onMounted(async () => {
      if (isRecaptchaEnabled.value) {
        await $recaptcha.init();
      }
    });
    onDeactivated(() => {
      if (isRecaptchaEnabled.value) {
        $recaptcha.destroy();
      }
    });

    return {
      barTitle,
      terms,
      error,
      forgotPasswordError,
      forgotPasswordLoading,
      form,
      setModal,
      handleForgotten,
      handleLogin,
      acceptMarketing,
      isThankYouAfterForgotten,
      loading,
      rememberMe,
      userEmail,
      userError,
      isRecaptchaEnabled,
      user,
      selectedCustomerGroup,
      loginAndRegisterModalOpen,
      $gt,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal {
  --modal-index: 50;
  --overlay-z-index: 49;
  .sf-modal__container {
    z-index: 99;
  }
  .error-message {
    color: var(--c-red);
  }
}
</style>

<style lang="scss">
.login-popup {
  text-transform: uppercase;
  .sf-bar {
    background: none;
    justify-content: start;
    font-family: var(--font-family-albert-sans);
    font-size: var(--font-size-20);
    height: var(--h-auto);
    padding: 1.25rem 1.25rem 0 1.25rem;
    div:nth-child(3) {
      margin-left: auto;
    }
    .sf-icon-path {
      fill: var(--c-black);
    }
  }
  .sf-modal__close {
    position: var(--absolute);
    right: 3.125rem;
    top: 3.4375rem;
    height: var(--h-auto);
    display: var(--hidden);
    @include to-mobile-max {
      right: 0.75rem;
      top: 0.75rem;
      display: var(--block);
    }
    .sf-icon.color-gray-secondary {
      width: 1.75rem;
      height: var(--h-auto);
      @include to-mobile-max {
        width: 1.25rem;
      }
      svg {
        path {
          fill: var(--c-black);
        }
      }
    }
  }
  .sf-overlay {
    background: var(--c-black);
    opacity: 0.7;
  }
  .modal {
    .sf-modal__container {
      background: var(--c-white);
      width: 31.25rem;
      right: var(--modal-right, 0);
      top: var(--modal-top, 0);
      transform: var(--modal-transform);
      @include to-tablet-max {
        width: 100%;
      }
      .sf-modal__content {
        padding: 3.125rem;
        font-size: var(--font-size-13);
        font-family: var(--font-family-albert-sans);
        @media (max-width: 768px) {
          padding: 1.25rem;
        }
        .sf-button-link {
          text-align: center;
          display: var(--block);
          cursor: var(--cursor-pointer);
          text-decoration: underline;
        }
        .no-account {
          text-align: center;
          display: var(--block);
          margin: 0;
          padding: 0.9375rem 0;
          font-family: var(--font-family-albert-sans);
        }
      }
    }
  }
  .sf-input,
  .sf-select {
    position: var(--relative);
    height: var(--h-auto);
    .sf-input__wrapper,
    .sf-select__wrapper {
      margin: 0;
      display: var(--flex);
      height: var(--h-auto);
      flex-direction: column;
      > input {
        border: 1px solid var(--c-primary-light);
        height: 3.125rem;
        padding: 0.625rem 0.9375rem;
        outline: none !important;
        background: transparent;
        color: var(--c-primary);
        font-size: var(--font-size-16);
        font-family: var(--font-family-albert-sans);
      }
    }
    .sf-input__error-message,
    .sf-select__error-message {
      height: auto;
      min-height: auto;
      font-size: 0.75rem;
      line-height: 0.875rem;
      > span,
      > div {
        padding: 5px 0 10px 0;
        &:empty {
          padding: 0;
        }
      }
    }
    .sf-input__label,
    .sf-select__label {
      position: var(--static);
      order: -1;
      color: var(--c-gray-dark);
      margin-bottom: 10px;
      transform: none;
      font-size: var(--font-size-13);
      font-family: var(--font-family-albert-sans);
    }
    > select {
      border: 1px solid var(--c-dark-D9D9D9);
      height: 3.125rem;
      padding: 0.625rem 0.9375rem;
      color: var(--c-gray-dark);
      margin: 0;
      font-size: var(--font-size-16);
      font-family: var(--font-family-albert-sans);
    }
  }
  .form {
    .sf-input {
      width: var(--w-full);
    }
    .form__element {
      margin: 0;
      display: var(--block);
      height: var(--h-auto);
      padding: 5px 0;
      width: var(--w-full);
    }
    .sf-checkbox {
      margin-bottom: 15px;
      width: var(--w-auto);
      .sf-checkbox__label {
        font-family: var(--font-family-albert-sans);
        font-size: var(--font-size-12);
      }
    }
    .sf-checkbox-row {
      display: var(--flex);
      align-items: flex-start;
      flex-wrap: wrap;
      position: var(--relative);
      padding-left: 25px;
      margin: 20px 0 15px 0;
      .sf-checkbox {
        width: var(--w-auto);
        position: var(--absolute);
        left: var(--left-0);
        top: var(--top-0);
      }
      a {
        text-decoration: underline;
        display: var(--flex);
        margin: 0 4px;
      }
    }
  }
  .sf-input__password-button {
    transform: none;
    top: inherit;
    bottom: 1px;
    background: none;
    right: 2px;
    padding: 13px 10px;
    &:hover {
      background: none;
    }
    .sf-input__password-icon {
      display: block;
      svg {
        display: var(--hidden);
      }
      &::after {
        display: var(--hidden);
      }
      width: 23px;
      height: 19px;
      background: url(/icons/password.svg) no-repeat;
      &.hidden {
        background: url(/icons/password1.svg) no-repeat;
      }
    }
  }
  .sf-input__bar::before,
  .sf-input__bar::after {
    display: var(--hidden);
  }
  .form__button,
  .action-button,
  .no-orders__button {
    margin: 0.625rem 0;
    border: 0;
    @include to-mobile-max {
      width: var(--w-full);
    }
  }
  .register-btn {
    margin-top: 1.875rem;
  }
  .loader.sf-loader {
    .sf-loader__overlay {
      background: var(--c-primary);
      .sf-loader__spinner {
        stroke: var(--c-white);
      }
    }
  }
}
</style>
