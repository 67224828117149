<template>
  <SfModal
    class="change-country-modal__container"
    v-model="showChangeCountry"
    :overlay="true"
    @close="toggleShowChangeCountry"
  >
    <div class="change-country-modal">
      <div class="change-country-modal__header">
        <div class="change-country-modal__header__title">
          {{ $gt('Select country') }}
        </div>
        <nuxt-img
          src="/icons/Cross.svg"
          class="change-country-modal__header__close"
          alt="Close modal icon"
          @click="toggleShowChangeCountry"
        />
      </div>
      <CustomSelect
        v-if="menuItems"
        class="change-country-modal__select"
        select-name="change-country-modal-select"
        :select-options="menuItems"
        :placeholder="$gt('Select country')"
        @selectChange="(v) => handleSelectCountry(v)"
      />
      <LoadingDots v-else />
      <component
        :is="selectedCountry ? 'a' : 'div'"
        :href="selectedCountry || ''"
        class="change-country-modal__cta"
        :class="!selectedCountry && 'change-country-modal__cta--disabled'"
        target="_blank"
        @click="toggleShowChangeCountry"
      >
        {{ $gt('Go to the site') || $gt('Select a country') }}
      </component>
    </div>
  </SfModal>
</template>

<script>
import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { SfModal } from '@storefront-ui/vue';
import { useUiState, useTranslation, useUiNotification } from '~/composables';
import { useMenu } from '@gemini-vsf/composables';
import { CustomSelect, LoadingDots } from '../General/FormElements';

export default defineComponent({
  name: 'ChangeCountryModal',
  components: {
    SfModal,
    CustomSelect,
    LoadingDots,
  },
  setup() {
    const { $gt } = useTranslation('translations');
    const { showChangeCountry, toggleShowChangeCountry } = useUiState();
    const { menu: changeCountry, search: getMenu } = useMenu('footerMenu');
    const selectedCountry = ref();
    const menuItems = ref();
    const { send: sendNotification } = useUiNotification();

    onMounted(async () => {
      try {
        await getMenu({ code: 'change-country' });
        menuItems.value = changeCountry.value.items[0].children.map((i) => ({
          label: i.label,
          value: i.link,
        }));
      } catch (error) {
        console.error('ChangeCountryModal ~ error:', error);
        toggleShowChangeCountry();
        sendNotification({
          id: Symbol('change_country_error'),
          message: $gt('Something went wrong. Please try again later.'),
          type: 'danger',
          icon: 'check',
          persist: true,
          title: 'change_country_error',
        });
      }
    });

    const handleSelectCountry = (country) => {
      try {
        selectedCountry.value = country.includes('http')
          ? `${country.split('://')[0]}://${country}`
          : `https://${country}`;
        return;
      } catch (error) {
        console.error(
          'ChangeCountryModal ~ handleSelectCountry ~ error:',
          error
        );
      }
      selectedCountry.value = country;
    };

    return {
      showChangeCountry,
      toggleShowChangeCountry,
      changeCountry,
      menuItems,
      handleSelectCountry,
      selectedCountry,
      $gt,
    };
  },
});
</script>

<style lang="scss">
.change-country-modal {
  @include flex-column;
  align-items: flex-start;
  justify-content: center;
  gap: 6rem;
  &__container {
    .sf-modal {
      &__container {
        background-color: var(--c-light-grey);
        border: none;
        --modal-width: 103vw;
        --modal-height: 103vh;
        z-index: 4;
        .sf-bar.sf-modal__bar.smartphone-only {
          display: none;
        }
        .sf-button--pure.sf-modal__close.desktop-only.sf-button {
          display: none !important;
        }
      }
      &__content {
        padding: 3.125rem;
        --modal-content-height: 100%;
      }
    }
  }
  &__cta {
    width: 100%;
    height: 3.125rem;
    font-family: var(--font-family-albert-sans);
    font-size: 0.9375rem;
    letter-spacing: 0.1031rem;
    line-height: 1.5625rem;
    @include flex-center;
    border-radius: 6.25rem;
    background-color: var(--c-black);
    text-transform: uppercase;
    color: var(--c-white);
    &--disabled {
      pointer-events: none;
      background-color: var(--c-grey-secondary);
    }
    &:hover {
      background-color: var(--c-white);
      color: var(--c-black);
      font-size: 0.9375rem;
      letter-spacing: 0.1031rem;
      line-height: 1.5625rem;
      outline: 1px solid var(--c-black);
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &__title {
      font-family: var(--font-family-plus-jakarta-sans);
      font-size: 1.875rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 2.1875rem;
      color: var(--c-black);
    }
    &__close {
      width: 1.5625rem;
      height: 1.5625rem;
      cursor: pointer;
    }
  }
  &__select {
    border: 0.5px solid var(--c-primary-lightest);
    border-radius: 100px;
    padding-bottom: 3.5rem;
  }
}
@include from-desktop-min {
  .change-country-modal {
    gap: 3.125rem;
    &__container {
      .sf-modal {
        &__container {
          --modal-width: 40.625rem;
          --modal-height: 23.2594rem;
          max-width: 100vw;
          max-height: 100vh;
        }
      }
    }
  }
}
</style>
